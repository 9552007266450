<template>
  <a-switch v-bind="$attrs" :checked="newChecked" @change="changeValue"> </a-switch>
</template>

<script>
export default {
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: {
      default: false
    },
    checkedVal: {
      default: true
    },
    unCheckedVal: {
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    newChecked() {
      return this.checked === this.checkedVal
    }
  },
  methods: {
    changeValue(val) {
      this.$emit('change', val ? this.checkedVal : this.unCheckedVal)
    }
  },
  mounted() {}
}
</script>