<template>
  <a-modal
    :title="data.id ? '编辑模版' : '新增模版'"
    dialog-class="template-edition-dialog"
    :dialog-style="{ top: '0px', height: '100vh' }"
    width="100%"
    v-model="visible"
    @ok="save"
    @cancel="onClose"
    :maskClosable="false"
    :footer="null"
    :z-index="1005"
  >
    <a-spin :spinning="loading" style="width: 100%; height: 100%">
      <iframe class="iframe" ref="iframe" :src="url" frameborder="0"></iframe>
    </a-spin>
  </a-modal>
</template>

<script>
import { addTemplateComponentApi, getTemplateComponentDetailApi, updateTemplateComponentApi } from '@/api/element.js';

export default {
  data() {
    return {
      visible: false,
      loading: false,
      data: '',
      form: {},
      url: '',
    };
  },
  methods: {
    async openModal(data) {
      Object.assign(this.$data, this.$options.data());
      this.data = data;
      this.visible = true;
      if (data.id) {
        await this.getTemplateDetail(data.id);
      } else {
        this.form = data;
      }
      window.addEventListener('message', this.messageEvent);
      this.url = `${process.env.VUE_APP_TEMPLATE_EDITOR}/templateTag`;
      this.loading = true;
    },

    onClose() {
      this.url = '';
      this.visible = false;
      window.removeEventListener('message', this.messageEvent);
    },

    /**
     * 获取模版详情
     * @param {string} id
     */
    async getTemplateDetail(id) {
      try {
        this.loading = true;
        const { code, data, message } = await getTemplateComponentDetailApi({ id });
        this.loading = false;
        if (code === 200) {
          if (data.topicSelectionTagId) {
            data.topicSelectionTagId = JSON.parse(data.topicSelectionTagId);
          }
          this.form = data;
        } else {
          this.$message.error(message);
        }
      } catch (error) {
        this.loading = false;
      }
    },

    /**
     * 监听消息
     * @param {object} event
     */
    messageEvent(event) {
      if (event.data.type && event.data.type === 'loaded') {
        this.loading = false;
        this.$refs.iframe.contentWindow.postMessage({ type: 'init', value: this.form }, '*');
      } else if (event.data.type && event.data.type === 'saveTemp') {
        this.save(event.data.value);
      }
    },

    async save(data) {
      Object.assign(this.form, data);
      const saveApi = this.form.id ? updateTemplateComponentApi : addTemplateComponentApi;
      this.loading = true;
      try {
        const { code, message } = await saveApi(this.form);
        this.loading = false;
        if (code === 200) {
          this.$message.success('保存成功');
          this.$emit('success', this.form.id);
          this.onClose();
        } else {
          this.$message.error(message);
        }
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .template-edition-dialog .ant-modal-content {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
::v-deep .template-edition-dialog .ant-modal-body {
  padding: 0;
  flex: 1;
  overflow: hidden;
}
::v-deep .ant-spin-container {
  width: 100%;
  height: 100%;
}
.iframe {
  width: 100%;
  height: 100%;
}
</style>
