<template>
  <div>
    <a-modal
      :title="form.id ? '编辑文字' : '新增文字'"
      v-model="visible"
      width="450px"
      :confirmLoading="saveLoading"
      @ok="save"
      @cancel="onClose"
      :maskClosable="false"
    >
      <a-spin :spinning="loading">
        <a-form-model
          ref="form"
          :model="form"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 18 }"
          :rules="elementTemplateEditionRules"
        >
          <a-form-model-item label="名称" prop="title">
            <a-input v-model="form.title" placeholder="请输入名称"></a-input>
          </a-form-model-item>
          <a-form-model-item label="分组" prop="cateId">
            <a-select v-model="form.cateId" placeholder="请选择分组" style="width: 100%" allowClear>
              <a-select-option v-for="item in categoryList" :key="item.id" :value="item.id">{{
                item.cateName
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="排序" prop="sortNum">
            <a-input-number
              v-model="form.sortNum"
              :precision="0"
              placeholder="请输入排序数字"
              style="width: 100%"
            ></a-input-number>
            <div>数字越大越靠前</div>
          </a-form-model-item>
        </a-form-model>
      </a-spin>
      <div slot="footer">
        <a-button type="primary" @click="next" v-if="!form.id" :loading="saveLoading">{{
          saveLoading ? '保存' : '下一步'
        }}</a-button>
        <a-button type="primary" @click="save({})" v-else :loading="saveLoading">确认</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { templateTypeList } from '@/constant/xhs.js';
import {
  getAllCategoryListApi,
  addTemplateComponentApi,
  getTemplateComponentDetailApi,
  updateTemplateComponentApi,
} from '@/api/element.js';
import { elementTemplateEditionRules } from '../data';
import _ from 'lodash';

export default {
  data() {
    return {
      templateTypeList,
      elementTemplateEditionRules,
      visible: false,
      loading: false,
      saveLoading: false,
      form: {
        cateId: undefined,
        tempType: undefined,
        cover: '',
        data: '',
        height: 0,
        id: '',
        sortNum: '',
        state: 1,
        tagData: '',
        title: '',
        topicSelectionTagId: [],
        width: 0,
      },
      categoryList: [],
    };
  },
  methods: {
    openModal(id) {
      Object.assign(this.$data, this.$options.data());
      this.form.id = id;
      this.getAllCategoryList();
      if (id) this.getTemplateDetail(id);
      this.visible = true;
    },
    onClose() {
      this.$refs.form && this.$refs.form.clearValidate();
      this.visible = false;
    },

    async getTemplateDetail(id) {
      try {
        this.loading = true;
        const { code, data, message } = await getTemplateComponentDetailApi({ id });

        if (code === 200) {
          if (data.topicSelectionTagId) {
            data.topicSelectionTagId = JSON.parse(data.topicSelectionTagId);
          }
          this.form = { ...data, cateId: data.cateId || undefined, tempType: data.tempType || undefined };
        } else {
          this.$message.error(message);
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getAllCategoryList() {
      const { code, data, message } = await getAllCategoryListApi({
        type: 3,
      });
      if (code === 200) {
        this.categoryList = data;
      } else {
        this.$message.error(message);
      }
    },

    next() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit('next', this.form);
        } else {
          return false;
        }
      });
    },

    async save(data) {
      Object.assign(this.form, data);
      const saveApi = this.form.id ? updateTemplateComponentApi : addTemplateComponentApi;
      this.saveLoading = true;
      try {
        const { code, message } = await saveApi(this.form);
        this.saveLoading = false;
        if (code === 200) {
          this.$message.success('保存成功');
          this.$emit('success', this.form.id);
          this.onClose();
        } else {
          this.$message.error(message);
        }
      } catch (error) {
        this.saveLoading = false;
      }
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
.tips {
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.35);
}
</style>
