<template>
  <div>
    <a-modal
      :title="form.id ? '编辑背景' : '新增背景'"
      v-model="visible"
      width="450px"
      :confirmLoading="saveLoading"
      @ok="save"
      @cancel="onClose"
      :maskClosable="false"
    >
      <a-spin :spinning="loading">
        <a-form-model
          ref="form"
          :model="form"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 18 }"
          :rules="elementImageEditionRules"
        >
          <a-form-model-item label="名称" prop="title">
            <a-input v-model.trim="form.title" placeholder="请输入名称"></a-input>
          </a-form-model-item>
          <a-form-model-item label="文件" prop="url">
            <base-upload
              style="height: 105px"
              :upload-text="'上传文件'"
              :upload-title="''"
              :accept-list="'.jpg,.jpeg,.png'"
              :has-file-list="uploadFileList"
              :limit-size="1"
              :params-data="uploadParamsData"
              :is-limit-image-size="false"
              @handleUrl="handleUrl"
              @handlePreview="(e) => openBigImg(e)"
            />
            <div>支持扩展名：.jpg .jpeg .png</div>
          </a-form-model-item>
          <a-form-model-item label="分组" prop="category">
            <a-select v-model="form.category" placeholder="请选择分组" style="width: 100%" allowClear>
              <a-select-option v-for="item in categoryList" :key="item.id" :value="item.id">{{
                item.cateName
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="排序" prop="sortNum">
            <a-input-number
              v-model="form.sortNum"
              :precision="0"
              placeholder="请输入排序数字"
              style="width: 100%"
            ></a-input-number>
            <div class="tips">数字越大越靠前</div>
          </a-form-model-item>
        </a-form-model>
      </a-spin>
      <div slot="footer">
        <a-button type="primary" @click="save()" :loading="saveLoading">确认</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { templateTypeList } from '@/constant/xhs.js';
import {
  getAllCategoryListApi,
  addImageComponentApi,
  updateImageComponentApi,
  getImageDetailApi,
} from '@/api/element.js';
import { elementImageEditionRules } from '../data';
import _ from 'lodash';
import BaseUpload from '@/components/BaseUpload/BaseUpload.vue';
import { aliImgTransform } from '@/utils/util';

export default {
  components: {
    BaseUpload,
  },
  data() {
    return {
      templateTypeList,
      elementImageEditionRules,
      visible: false,
      loading: false,
      saveLoading: false,
      form: {
        id: '',
        category: undefined,
        height: 0,
        model: '',
        original: '',
        sortNum: '',
        state: 1,
        thumb: '',
        title: '',
        type: '',
        url: '',
        width: 0,
      },
      categoryList: [],

      // 上传相关
      uploadFileList: [],
      uploadParamsData: {
        appId: 'bfz',
        filePath: '/bfz/image/',
      },
    };
  },
  methods: {
    openModal(id) {
      Object.assign(this.$data, this.$options.data());
      this.form.id = id;
      this.getAllCategoryList();
      if (id) this.getImageDetail(id);
      this.visible = true;
    },
    onClose() {
      this.$refs.form.clearValidate();
      this.visible = false;
    },
    openBigImg(url) {
      this.$emit('preview', url);
    },

    async getImageDetail(id) {
      try {
        this.loading = true;
        const { code, data, message } = await getImageDetailApi({ id });

        if (code === 200) {
          this.uploadFileList = [
            {
              uid: -1,
              name: 'image/png',
              status: 'done',
              url: data.url,
            },
          ];
          this.form = { ...data, cateId: data.cateId || undefined, tempType: data.tempType || undefined };
        } else {
          this.$message.error(message);
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getAllCategoryList() {
      const { code, data, message } = await getAllCategoryListApi({
        type: 4,
      });
      if (code === 200) {
        this.categoryList = data;
      } else {
        this.$message.error(message);
      }
    },

    getImageSize(url) {
      return new Promise(function (resolve, reject) {
        let image = new Image();
        image.onload = function () {
          resolve({
            width: image.width,
            height: image.height,
          });
        };
        image.onerror = function () {
          reject(new Error('error'));
        };
        image.src = url;
      });
    },

    handleUrl(file) {
      this.form.thumb = file.xhr;
      this.form.url = file.xhr;
      this.form.type = 'image';
      this.getImageSize(file.response.url).then((res) => {
        this.form.width = res.width;
        this.form.height = res.height;
      });
    },

    async save() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.saveLoading = true;
          try {
            const saveApi = this.form.id ? updateImageComponentApi : addImageComponentApi;
            const { code, message } = await saveApi(this.form);
            this.saveLoading = false;
            if (code === 200) {
              this.$message.success('保存成功');
              this.$emit('success');
              this.onClose();
            } else {
              this.$message.error(message);
            }
          } catch (error) {
            this.saveLoading = false;
          }
        }
      });
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
.tips {
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.35);
}
</style>
