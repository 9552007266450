<template>
  <div>
    <a-tabs v-model="tabKey" @change="handleTabChange" size="large">
      <a-tab-pane v-for="item in tabs" :key="item.value" :tab="item.label" :disabled="loading"></a-tab-pane>
    </a-tabs>
    <template v-if="tabKey != 7">
      <a-row :gutter="[15, 10]" style="margin-bottom: 20px">
        <a-col :span="3" v-if="tabKey === 1">
          <a-select
            v-model="form.tempType"
            placeholder="请选择元素类型"
            style="width: 100%"
            allowClear
            @change="
              form.cate = undefined;
              getAllCategoryList();
              getDataList(true);
            "
          >
            <a-select-option v-for="item in templateTypeList" :key="item.value" :value="item.value">{{
              item.label
            }}</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="3">
          <a-select
            v-model="form.cate"
            placeholder="请选择分组"
            style="width: 100%"
            allowClear
            @change="getDataList(true)"
          >
            <a-select-option v-for="item in categoryList" :key="item.id" :value="item.id">{{
              item.cateName
            }}</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="3">
          <a-select
            v-model="form.status"
            placeholder="请选择状态"
            style="width: 100%"
            allowClear
            @change="getDataList(true)"
          >
            <a-select-option v-for="item in stateList" :key="item.value" :value="item.value">{{
              item.label
            }}</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="3" v-if="tabKey === 1">
          <a-select style="width: 100%" placeholder="打标" allowClear v-model="form.isTag" @change="getDataList(true)">
            <a-select-option :value="1">已打标</a-select-option>
            <a-select-option :value="0">未打标</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="4" v-if="tabKey === 1 && showTag">
          <TopicTreeInput
            ref="TopicTreeInput"
            style="width: 100%"
            v-model="topicSelectionTagIdList"
            :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
            :replaceFields="{ title: 'name' }"
            placeholder="选题"
            allow-clear
            multiple
            tree-default-expand-all
            :maxTagCount="1"
            :isAccept="true"
            :acceptList="tagList"
            @input="getDataList(true)"
          />
        </a-col>
        <a-col :span="3">
          <a-select
            v-model="form.modifier"
            placeholder="请选择操作人"
            style="width: 100%"
            allowClear
            @change="getDataList(true)"
          >
            <a-select-option v-for="(item, index) in modifierList" :key="index" :value="item.modifier">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="3">
          <a-input-search
            allowClear
            placeholder="搜索名称"
            v-model.trim="form.title"
            @search="getDataList(true)"
          ></a-input-search>
        </a-col>
        <a-col :offset="columnOffset" :span="1" style="text-align: right">
          <a-button v-if="tabKey === 1" type="primary" @click="addTemplate">添加</a-button>
          <a-button v-if="tabKey === 2" type="primary" @click="addSticker">添加</a-button>
          <a-button v-if="tabKey === 3" type="primary" @click="addCharacter">添加</a-button>
          <a-button v-if="tabKey === 4" type="primary" @click="addImage">添加</a-button>
          <a-button v-if="tabKey === 5" type="primary" @click="addFont">添加</a-button>
          <a-button v-if="tabKey === 6" type="primary" @click="addFilter">添加</a-button>
        </a-col>
      </a-row>
      <a-table
        :columns="tableColumns"
        row-key="id"
        :pagination="false"
        :loading="loading"
        :data-source="templateList"
        @change="onTableChange"
      >
        <div slot="sortNumTitle">
          排序
          <div style="display: inline-block; vertical-align: middle">
            <a-icon
              :class="[this.sortMap['sortNum'] === 'asc' ? 'caret-up-class-on' : 'caret-up-class']"
              type="caret-up"
            ></a-icon>
            <a-icon
              :class="[this.sortMap['sortNum'] === 'desc' ? 'caret-down-class-on' : 'caret-down-class']"
              type="caret-down"
            ></a-icon>
          </div>
        </div>
        <div slot="mTimeTitle">
          更新时间
          <div style="display: inline-block; vertical-align: middle">
            <a-icon
              :class="[this.sortMap['mtime'] === 'asc' ? 'caret-up-class-on' : 'caret-up-class']"
              type="caret-up"
            ></a-icon>
            <a-icon
              :class="[this.sortMap['mtime'] === 'desc' ? 'caret-down-class-on' : 'caret-down-class']"
              type="caret-down"
            ></a-icon>
          </div>
        </div>
        <div slot="state" slot-scope="text, record, index">
          <any-switch
            v-if="tabKey === 1 || tabKey === 3"
            v-model="record.state"
            :checkedVal="1"
            :unCheckedVal="0"
            @change="handleTemplateStatusChange(index)"
          >
          </any-switch>
          <any-switch
            v-if="tabKey === 2"
            v-model="record.state"
            :checkedVal="1"
            :unCheckedVal="0"
            @change="handleMaterialStatusChange(index)"
          >
          </any-switch>
          <any-switch
            v-if="tabKey === 4"
            v-model="record.status"
            :checkedVal="1"
            :unCheckedVal="0"
            @change="handleImageStatusChange(index)"
          >
          </any-switch>
          <any-switch
            v-if="tabKey === 5"
            v-model="record.status"
            :checkedVal="1"
            :unCheckedVal="0"
            @change="handleFontStatusChange(index)"
          >
          </any-switch>
          <any-switch
            v-if="tabKey === 6"
            v-model="record.status"
            :checkedVal="1"
            :unCheckedVal="0"
            @change="handleFilterStatusChange(index)"
          >
          </any-switch>
        </div>
        <div slot="cover" slot-scope="text, record" class="info-left" @click="openBigImg(getRealImage(record))">
          <base-img
            :src="getCover(record)"
            alt="图片"
            referrerpolicy="no-referrer"
            style="cursor: pointer; object-fit: contain"
          />
        </div>
        <div slot="topic" slot-scope="text, record">
          <div v-if="record.tagName" :title="record.tagName">
            <a-tag color="#b5dbff">{{
              record.tagName ? (record.tagName.length > 6 ? `${record.tagName.slice(0, 6)}...` : record.tagName) : ''
            }}</a-tag>
          </div>
          <div v-else>-</div>
        </div>
        <div slot="operation" slot-scope="text, record">
          <template v-if="tabKey === 1">
            <div>
              <a @click="openElementTemplateEditionModal(record.id)">编辑分组名称</a>
            </div>
            <a-dropdown>
              <a style="display: block" @click="(e) => e.preventDefault()"> 更多 <a-icon type="down" /> </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="openTemplateTagEditionModal(record)">编辑模版打标</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="openTemplateEditionModal(record.id)">编辑模版</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="handleDel(record.id, tabKey)">删除</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>
          <template v-if="tabKey === 2">
            <a-space size="large">
              <a @click="openElementStickerEditionModal(record.id)">编辑</a>
              <a @click="handleDel(record.id, tabKey)">删除</a>
            </a-space>
          </template>
          <template v-if="tabKey === 3">
            <a-space size="large">
              <a @click="openElementCharacterEditionModal(record.id)">编辑分组名称</a>
              <a-dropdown>
                <a @click="(e) => e.preventDefault()"> 更多 <a-icon type="down" /> </a>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a @click="openTemplateEditionModal(record.id)">编辑文字</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a @click="handleDel(record.id, tabKey)">删除</a>
                  </a-menu-item>
                </a-menu></a-dropdown
              >
            </a-space>
          </template>
          <template v-if="tabKey === 4">
            <a-space size="large"
              ><a @click="openElementImageEditionModal(record.id)">编辑</a>
              <a @click="handleDel(record.id, tabKey)">删除</a>
            </a-space>
          </template>
          <template v-if="tabKey === 5">
            <a-space size="large"
              ><a @click="openElementFontEditionModal(record.id)">编辑</a>
              <a @click="handleDel(record.id, tabKey)">删除</a>
            </a-space>
          </template>
          <template v-if="tabKey === 6">
            <a-space size="large"
              ><a @click="openFilterEditionModal(record.id)">编辑</a>
              <a @click="handleDel(record.id, tabKey)">删除</a>
            </a-space>
          </template>
        </div>
      </a-table>
      <base-pagination
        :currentPage="pagination.page"
        :options="['10', '30', '50']"
        :pageSize="pagination.pageSize"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
      />
    </template>
    <template v-else>
      <ElementGroup />
    </template>
    <!-- 图片预览 -->
    <preview-modal :visible="preview_visible" :preview-src="preview_src" width="50%" @cancel="handlePreviewCancel" />
    <ElementTemplateEditionModal
      :categoryList="categoryList"
      ref="ElementTemplateEditionModal"
      @next="openTemplateTagEditionModal"
      @success="templateEditionSuccess"
    />
    <ElementStickerEditionModal ref="ElementStickerEditionModal" @preview="openBigImg" @success="getDataList()" />
    <ElementImageEditionModal ref="ElementImageEditionModal" @preview="openBigImg" @success="getDataList()" />
    <ElementCharacterEditionModal
      ref="ElementCharacterEditionModal"
      @next="openTemplateAdditionModal"
      @success="templateEditionSuccess"
    />
    <ElementFontEditionModal ref="ElementFontEditionModal" @preview="openBigImg" @success="getDataList()" />
    <TemplateTagEditionModal ref="TemplateTagEditionModal" @success="templateEditionSuccess" />
    <TemplateEditionModal ref="TemplateEditionModal" @success="templateEditionSuccess" />
    <ElementFilterEditionModal ref="ElementFilterEditionModal" @preview="openBigImg" @success="getDataList()" />
  </div>
</template>

<script>
import { tabs, stateList, columns } from './data';
import {
  getAllCategoryListApi,
  getTemplateComponentListApi,
  updateTemplateComponentApi,
  getMaterialComponentListApi,
  getImageComponentListApi,
  getFontListApi,
  updateFontApi,
  updateMaterialComponentApi,
  updateImageComponentApi,
  getFilterListApi,
  updateFilterApi,
  deleteTempApi,
  deleteMaterialApi,
  deleteBgApi,
  deleteFontApi,
  deleteFilterApi,
  getModifierListByTemplate,
  getModifierListBySticker,
  getModifierListByBackground,
  getModifierListByFont,
  getModifierListByFilter,
} from '@/api/element.js';
import BaseImg from '@/components/BaseImg';
import AnySwitch from '@/components/AnySwitch/AnySwitch.vue';
import { templateTypeList } from '@/constant/xhs.js';
import PreviewModal from '@/components/PreviewModalVideo/PreviewModalVideo';
import ElementTemplateEditionModal from './components/ElementTemplateEditionModal.vue';
import TemplateEditionModal from './components/TemplateEditionModal.vue';
import TemplateTagEditionModal from './components/TemplateTagEditionModal.vue';
import ElementStickerEditionModal from './components/ElementStickerEditionModal.vue';
import ElementCharacterEditionModal from './components/ElementCharacterEditionModal.vue';
import ElementImageEditionModal from './components/ElementImageEditionModal.vue';
import ElementFontEditionModal from './components/ElementFontEditionModal.vue';
import ElementFilterEditionModal from './components/ElementFilterEditionModal.vue';
import TopicTreeInput from '../../../../components/xhsAgencyTool/TopicTreeInput.vue';
import ElementGroup from '@/pages/app/xhsAgency/ElementGroup/index.vue';
import api from '@/api/xhsAgencyApi';

export default {
  components: {
    BaseImg,
    AnySwitch,
    PreviewModal,
    ElementTemplateEditionModal,
    TemplateTagEditionModal,
    TemplateEditionModal,
    ElementStickerEditionModal,
    ElementCharacterEditionModal,
    ElementImageEditionModal,
    ElementFontEditionModal,
    ElementFilterEditionModal,
    TopicTreeInput,
    ElementGroup,
  },
  data() {
    const delApi = {
      1: deleteTempApi,
      2: deleteMaterialApi,
      3: deleteTempApi,
      4: deleteBgApi,
      5: deleteFontApi,
      6: deleteFilterApi,
    };
    const modifierApi = {
      1: getModifierListByTemplate,
      2: getModifierListBySticker,
      4: getModifierListByBackground,
      5: getModifierListByFont,
      6: getModifierListByFilter,
    };
    return {
      modifierApi,
      delApi,
      tabs,
      columns,
      templateTypeList,
      stateList,
      tabKey: 1,
      pagination: {
        page: 1,
        pageSize: 10,
        total: 1,
      },
      form: {
        cate: undefined,
        status: undefined,
        tempType: undefined,
        sort: ['mtime,desc'],
        isTag: undefined,
      },
      sortMap: { mtime: 'desc' },
      loading: false,
      templateList: [],
      categoryList: [],

      // 图片预览
      preview_visible: false,
      preview_src: '',

      topicSelectionTagIdList: [],

      tagList: [],
      tagListFlat: [],
      showTag: false,
      allModifierList: [],
    };
  },
  computed: {
    tableColumns() {
      switch (this.tabKey) {
        case 1:
          return columns;
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
          return columns.filter((item) => !['类型', '模板打标', '适用选题'].includes(item.title));
        default:
          return [];
      }
    },
    getListApi() {
      switch (this.tabKey) {
        case 1:
        case 3:
          return getTemplateComponentListApi;
        case 2:
          return getMaterialComponentListApi;
        case 4:
          return getImageComponentListApi;
        case 5:
          return getFontListApi;
        case 6:
          return getFilterListApi;
        default:
          return new Promise();
      }
    },
    columnOffset() {
      switch (this.tabKey) {
        case 1:
          return 0;
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
          return 10;
        default:
          return 5;
      }
    },
    modifierList() {
      if (this.tabKey === 1 || this.tabKey === 3) {
        return this.allModifierList.filter((item) => item.tab === 1);
      } else {
        return this.allModifierList.filter((item) => item.tab === this.tabKey);
      }
    },
  },
  methods: {
    handleTabChange() {
      this.form.cate = undefined;
      this.form.status = undefined;
      this.form.tempType = undefined;
      this.form.sort = ['mtime,desc'];
      this.form.modifier = undefined;
      this.form.title = undefined;
      this.sortMap = { mtime: 'desc' };
      if (this.tabKey === 7) return;
      this.getAllCategoryList();
      this.getDataList(true);
    },
    /**
     * 获取分组列表
     */
    async getAllCategoryList() {
      if (this.tabKey === 1 && !this.form.tempType) {
        this.categoryList = [];
        return;
      }
      const { code, data, message } = await getAllCategoryListApi({
        type: this.tabKey,
        tempType: this.form.tempType,
      });
      if (code === 200) {
        this.categoryList = data;
      } else {
        this.$message.error(message);
      }
    },
    async getDataList(isFirst) {
      if (isFirst) {
        this.pagination.page = 1;
      }
      this.loading = true;
      try {
        const params = {
          ...this.form,
          cateType: this.tabKey,
          page: this.pagination.page,
          pageSize: this.pagination.pageSize,
        };
        if (this.tabKey === 1) {
          params.topicSelectionTagIdList = this.topicSelectionTagIdList?.join(',') || undefined;
        }
        const { code, data, message } = await this.getListApi(params);
        this.loading = false;
        if (code === 200) {
          if (this.tabKey === 1) {
            data.list.forEach((item) => {
              const tagId = JSON.parse(item.topicSelectionTagId);
              if (tagId && tagId.length) {
                item.tagName = this.tagListFlat.find((tag) => tag.id === tagId[0]).name;
              } else {
                item.tagName = '';
              }
              item.isTag = item.tagData ? true : false;
            });
          }
          // 字体，赋值一下title
          if (this.tabKey === 5) {
            data.list.forEach((item) => {
              item.title = item.value;
            });
          }
          this.templateList = data.list;
          this.pagination.total = data.total;
        } else {
          this.$message.error(message);
        }
      } catch (error) {
        console.log('error:', error);
        this.loading = false;
      }
    },
    /**
     * 翻页
     * @param {*} page
     * @param {*} pageSize
     */
    handlePaginationChange(page, pageSize) {
      this.pagination.page = page;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    onTableChange(pagination, filters, sorter) {
      const field = sorter.field;
      if (!this.sortMap[`${field}`]) {
        this.sortMap[`${field}`] = `asc`;
      } else if (this.sortMap[`${field}`] === 'asc') {
        this.sortMap[`${field}`] = `desc`;
      } else {
        delete this.sortMap[`${field}`];
      }
      this.form.sort =
        Object.keys(this.sortMap).length > 0
          ? Object.keys(this.sortMap).map((key) => `${key},${this.sortMap[key]}`)
          : undefined;
      this.getDataList();
    },
    getCover(record) {
      switch (this.tabKey) {
        case 1:
        case 3:
          return record.cover;
        case 2:
        case 4:
        case 6:
          return record.thumb;
        case 5:
          return record.preview;
        default:
          return '';
      }
    },
    getRealImage(record) {
      switch (this.tabKey) {
        case 1:
        case 3:
          return record.cover;
        case 2:
        case 6:
          return record.thumb;
        case 4:
          return record.url;
        case 5:
          return record.preview;
        default:
          return '';
      }
    },
    async handleTemplateStatusChange(index) {
      const data = {
        id: this.templateList[index].id,
        state: this.templateList[index].state,
      };
      try {
        const { code, message } = await updateTemplateComponentApi(data);
        if (code === 200) {
          this.$message.success(this.templateList[index].state ? '上架成功' : '下架成功');
          this.getDataList();
        } else {
          this.$message.error(message);
        }
      } catch (error) {
        console.log('error:', error);
      }
    },
    async handleMaterialStatusChange(index) {
      const data = {
        id: this.templateList[index].id,
        state: this.templateList[index].state,
      };
      try {
        const { code, message } = await updateMaterialComponentApi(data);
        if (code === 200) {
          this.$message.success(this.templateList[index].state ? '上架成功' : '下架成功');
          this.getDataList();
        } else {
          this.$message.error(message);
        }
      } catch (error) {
        console.log('error:', error);
      }
    },
    async handleImageStatusChange(index) {
      const data = {
        id: this.templateList[index].id,
        status: this.templateList[index].status,
      };
      try {
        const { code, message } = await updateImageComponentApi(data);
        if (code === 200) {
          this.$message.success(this.templateList[index].status ? '上架成功' : '下架成功');
          this.getDataList();
        } else {
          this.$message.error(message);
        }
      } catch (error) {
        console.log('error:', error);
      }
    },
    async handleFontStatusChange(index) {
      const data = {
        id: this.templateList[index].id,
        status: this.templateList[index].status,
      };
      try {
        const { code, message } = await updateFontApi(data);
        if (code === 200) {
          this.$message.success(this.templateList[index].status ? '上架成功' : '下架成功');
          this.getDataList();
        } else {
          this.$message.error(message);
        }
      } catch (error) {
        console.log('error:', error);
      }
    },
    async handleFilterStatusChange(index) {
      const data = {
        id: this.templateList[index].id,
        status: this.templateList[index].status,
      };
      try {
        const { code, message } = await updateFilterApi(data);
        if (code === 200) {
          this.$message.success(this.templateList[index].status ? '上架成功' : '下架成功');
          this.getDataList();
        } else {
          this.$message.error(message);
        }
      } catch (error) {
        console.log('error:', error);
      }
    },

    /**
     * 查看图片
     * @param {*} url 图片地址
     */
    openBigImg(url) {
      if (!url) {
        return this.$message.error('URL为空，不支持查看！');
      }
      this.width = '480px';
      this.preview_visible = true;
      this.preview_src = url;
    },
    /**
     * 预览图片
     */
    handlePreviewCancel() {
      this.preview_src = '';
      this.preview_visible = false;
    },
    /**
     * 新增模板
     * @return {*}
     */
    addTemplate() {
      this.$refs['ElementTemplateEditionModal'].openModal();
    },
    /**
     * 打开模板打标弹窗
     * @param {object} data 模板信息
     */
    openTemplateTagEditionModal(data) {
      this.$refs['TemplateTagEditionModal'].openModal(data);
    },
    /**
     * 打开模板新增弹窗
     * @param {object} data 模板信息
     */
    openTemplateAdditionModal(data) {
      this.$refs['TemplateEditionModal'].openAddModal(data);
    },
    /**
     * 模板保存成功
     * @param {string} id 模板id
     */
    templateEditionSuccess(id) {
      if (!id) {
        if (this.tabKey === 1) {
          this.$refs['ElementTemplateEditionModal'].onClose();
        } else if (this.tabKey === 3) {
          this.$refs['ElementCharacterEditionModal'].onClose();
        }
      }
      this.getDataList();
    },
    /**
     * 打开编辑模板分组名称弹窗
     */
    openElementTemplateEditionModal(id) {
      this.$refs['ElementTemplateEditionModal'].openModal(id);
    },
    /**
     * 打开模板编辑弹窗
     * @param {string} id 模板id
     */
    openTemplateEditionModal(id) {
      this.$refs['TemplateEditionModal'].openModal(id);
    },

    /**
     * 新增贴纸
     */
    addSticker() {
      this.$refs['ElementStickerEditionModal'].openModal();
    },
    /**
     * 打开贴纸编辑弹窗
     * @param {string} id 模板id
     */
    openElementStickerEditionModal(id) {
      this.$refs['ElementStickerEditionModal'].openModal(id);
    },

    /**
     * 新增文字
     */
    addCharacter() {
      this.$refs['ElementCharacterEditionModal'].openModal();
    },
    /**
     * 打开文字模板分组名称弹窗
     */
    openElementCharacterEditionModal(id) {
      this.$refs['ElementCharacterEditionModal'].openModal(id);
    },

    /**
     * 新增背景
     */
    addImage() {
      this.$refs['ElementImageEditionModal'].openModal();
    },
    /**
     * 编辑背景
     * @param {*} id
     */
    openElementImageEditionModal(id) {
      this.$refs['ElementImageEditionModal'].openModal(id);
    },

    /**
     * 新增字体
     */
    addFont() {
      this.$refs['ElementFontEditionModal'].openModal();
    },
    /**
     * 编辑字体
     * @param {*} id
     */
    openElementFontEditionModal(id) {
      this.$refs['ElementFontEditionModal'].openModal(id);
    },
    // 添加滤镜
    addFilter() {
      this.$refs['ElementFilterEditionModal'].openModal();
    },
    // 编辑滤镜
    openFilterEditionModal(id) {
      this.$refs['ElementFilterEditionModal'].openModal(id);
    },
    // 删除
    handleDel(id, type) {
      const api = this.delApi[type];
      this.$confirm({
        title: '提示',
        content: `确定删除该${this.tabs[type - 1].label}？`,
        onOk: async () => {
          const { code, message } = await api(id);
          if (code === 200) {
            this.$message.success('操作成功');
            if (this.templateList.length == 1 && this.pagination.page != 1) this.pagination.page--;
            this.getDataList();
          } else {
            this.$message.error(message);
          }
        },
      });
    },
    // 获取全部选题
    async getAllTagId() {
      const { code, data, message } = await api.getTopicTagTree(0);
      if (code === 200) {
        this.tagList = data.map(this.initTagValue);
        this.tagListFlat = this.flattenTree(this.tagList);
        this.showTag = true;
      } else {
        return this.$message.error(`${message}`);
      }
    },
    // 选题初始化
    initTagValue(data) {
      if (data.children && data.children.length > 0) {
        data.children = data.children.map(this.initTagValue);
      }
      return {
        ...data,
        name: data.tagName,
        value: data.id,
        key: data.id,
        disabled: data.id === this.originId,
      };
    },
    // 选题降维
    flattenTree(tree) {
      const result = [];
      function traverse(node) {
        result.push(node);
        if (node.children) {
          for (const child of node.children) {
            traverse(child);
          }
        }
      }
      for (const item of tree) {
        traverse(item);
      }

      return result;
    },

    // 获取操作人列表
    getAllCreatorList() {
      Object.keys(this.modifierApi).forEach(async (key) => {
        const { code, data } = await this.modifierApi[key]();
        if (code === 200) {
          this.allModifierList.push(...data.map((v) => ({ ...v, tab: Number(key) })));
        }
      });
    },
  },
  async created() {
    this.getAllCreatorList();
    await this.getAllTagId();
    this.getDataList();
  },
  async mounted() {
    this.getAllCategoryList();
  },
};
</script>

<style scoped lang="scss">
::v-deep .ant-table-column-sorter {
  display: none !important;
}
::v-deep .ant-table-tbody > tr > td.ant-table-column-sort {
  background: inherit;
}
::v-deep .ant-table-thead > tr > th.ant-table-column-sort {
  background: #fafafa;
}
.caret-up-class {
  font-size: 10px;
  color: #bfbfbf;
  display: block;
}
.caret-up-class-on {
  font-size: 10px;
  color: #40a9ff;
  display: block;
}
.caret-down-class {
  font-size: 10px;
  color: #bfbfbf;
  margin-top: -0.325em;
  display: block;
}
.caret-down-class-on {
  font-size: 10px;
  margin-top: -0.325em;
  color: #40a9ff;
  display: block;
}

.info-left {
  width: 60px;
  height: 60px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
