<template>
  <div>
    <a-modal
      :title="form.id ? '编辑滤镜' : '新增滤镜'"
      v-model="visible"
      width="450px"
      :confirmLoading="saveLoading"
      @ok="save"
      @cancel="onClose"
      :maskClosable="false"
    >
      <a-spin :spinning="loading">
        <a-form-model
          ref="form"
          :model="form"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 18 }"
          :rules="elementFilterEditionRules"
        >
          <a-form-model-item label="上传文件" prop="fileUrl">
            <base-upload
              key="fileUrl"
              style="height: 105px"
              :upload-text="'上传文件'"
              :upload-title="''"
              :accept-list="'.zip'"
              :has-file-list="uploadFilterList"
              :limit-size="15"
              :params-data="uploadParamsData"
              :is-limit-image-size="false"
              :is-show-preview-icon="false"
              @handleUrl="(url) => handleUrl(url, 'fileUrl')"
            />
            <div>支持扩展名：.zip</div>
          </a-form-model-item>
          <a-form-model-item label="上传封面" prop="thumb">
            <base-upload
              key="thumb"
              style="height: 105px"
              :upload-text="'上传封面'"
              :upload-title="''"
              :accept-list="'.jpg,.jpeg,.png'"
              :has-file-list="uploadCoverList"
              :limit-size="1"
              :params-data="uploadParamsData"
              :is-limit-image-size="false"
              @handleUrl="(url) => handleUrl(url, 'thumb')"
              @handlePreview="(e) => openBigImg(e)"
            />
            <div>支持扩展名：.jpg .jpeg .png</div>
          </a-form-model-item>
          <a-form-model-item label="名称" prop="title">
            <a-input v-model="form.title" placeholder="请输入名称"></a-input>
          </a-form-model-item>
          <a-form-model-item label="分组" prop="category">
            <a-select v-model="form.category" placeholder="请选择分组" style="width: 100%" allowClear>
              <a-select-option v-for="item in categoryList" :key="item.id" :value="item.id">{{
                item.cateName
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="排序" prop="sortNum">
            <a-input-number
              v-model="form.sortNum"
              :precision="0"
              placeholder="请输入排序数字"
              style="width: 100%"
            ></a-input-number>
            <div class="tips">数字越大越靠前</div>
          </a-form-model-item>
        </a-form-model>
      </a-spin>
      <div slot="footer">
        <a-button type="primary" @click="save()" :loading="saveLoading">确认</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { templateTypeList } from '@/constant/xhs.js';
import { getAllCategoryListApi, addFilterApi, updateFilterApi, getFilterDetailApi } from '@/api/element.js';
import { elementFilterEditionRules } from '../data';
import _ from 'lodash';
import BaseUpload from '@/components/BaseUpload/BaseUpload.vue';

export default {
  components: {
    BaseUpload,
  },
  data() {
    return {
      templateTypeList,
      elementFilterEditionRules,
      visible: false,
      loading: false,
      saveLoading: false,
      form: {
        fileUrl: undefined,
        id: undefined,
        sortNum: undefined,
        status: 1,
        thumb: undefined,
        title: undefined,
      },
      categoryList: [],

      // 上传相关
      uploadCoverList: [],
      uploadFilterList: [],
      uploadParamsData: {
        appId: 'bfz',
        filePath: '/bfz/filter/',
        ossFilePath: '/bfz/filter/',
      },
    };
  },
  methods: {
    openModal(id) {
      Object.assign(this.$data, this.$options.data());
      this.form.id = id;
      this.getAllCategoryList();
      if (id) this.getFilterDetail(id);
      this.visible = true;
    },
    onClose() {
      this.$refs.form.clearValidate();
      this.visible = false;
    },
    openBigImg(url) {
      this.$emit('preview', url);
    },

    async getFilterDetail(id) {
      try {
        this.loading = true;
        const { code, data, message } = await getFilterDetailApi({ id });
        if (code === 200) {
          console.log(data);
          if (data.thumb) {
            this.uploadCoverList = [
              {
                uid: -1,
                name: 'image/png',
                status: 'done',
                url: data.thumb,
              },
            ];
          }
          if (data.fileUrl) {
            this.uploadFilterList = [
              {
                uid: -1,
                name: 'filterFile',
                status: 'done',
                url: data.fileUrl,
              },
            ];
          }
          data.category = data.category !== '0' ? data.category : undefined;
          this.form = data;
          console.log('this.form--->', this.form);
        } else {
          this.$message.error(message);
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getAllCategoryList() {
      const { code, data, message } = await getAllCategoryListApi({
        type: 6,
      });
      if (code === 200) {
        this.categoryList = data;
      } else {
        this.$message.error(message);
      }
    },

    getImageSize(url) {
      return new Promise(function (resolve, reject) {
        let image = new Image();
        image.onload = function () {
          resolve({
            width: image.width,
            height: image.height,
          });
        };
        image.onerror = function () {
          reject(new Error('error'));
        };
        image.src = url;
      });
    },

    handleUrl(file, type) {
      if (!file) {
        this.form[type] = '';
      } else {
        const filterSuffixList = ['zip'];
        if (filterSuffixList.indexOf(file.suffix) != -1) {
          this.form.fileUrl = file.xhr;
          if (!this.form.title) {
            this.form.title = file.name.replace(`.${file.suffix}`, '');
            this.$refs.form.clearValidate('title');
          }
          this.$refs.form.clearValidate('fileUrl');
        } else {
          this.form.thumb = file.xhr;
          this.$refs.form.clearValidate('thumb');
        }
      }
    },

    async save() {
      console.log(this.form);
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const params = { ...this.form };
          const saveApi = this.form.id ? updateFilterApi : addFilterApi;
          this.saveLoading = true;
          try {
            const { code, message } = await saveApi(params);
            this.saveLoading = false;
            if (code === 200) {
              this.$message.success('保存成功');
              this.$emit('success');
              this.onClose();
            } else {
              this.$message.error(message);
            }
          } catch (error) {
            this.saveLoading = false;
          }
        }
      });
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
.tips {
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.35);
}
</style>
