import { templateTypeMap } from '@/constant/xhs.js';

export const tabs = [
  { label: '模板', value: 1 },
  { label: '贴纸', value: 2 },
  { label: '文字', value: 3 },
  { label: '背景', value: 4 },
  { label: '字体', value: 5 },
  { label: '滤镜', value: 6 },
  { label: '元素分组', value: 7 },
];

export const stateList = [
  { label: '已下架', value: 0 },
  { label: '已上架', value: 1 },
];

export const columns = [
  {
    // title: '排序',
    slots: { title: 'sortNumTitle' },
    dataIndex: 'sortNum',
    sorter: true,
    width: 100,
  },
  {
    title: '上架',
    width: 100,
    scopedSlots: { customRender: 'state' },
  },
  {
    title: '预览',
    scopedSlots: { customRender: 'cover' },
  },
  {
    title: '名称',
    width: 150,
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '类型',
    width: 100,
    customRender(record) {
      return templateTypeMap[record.tempType] || '';
    },
  },
  {
    title: '分组',
    dataIndex: 'cateName',
  },
  {
    title: '模板打标',
    customRender(record) {
      return record.isTag ? '已打标':'未打标';
    },
  },
  {
    title: '适用选题',
    scopedSlots: { customRender: 'topic' },
  },
  {
    title: '操作人',
    customRender(record) {
      return record.modifier || '-';
    },
  },
  {
    slots: { title: 'mTimeTitle' },
    // title: '更新时间',
    dataIndex: 'mtime',
    sorter: true,
  },
  {
    align: 'center',
    title: '操作',
    fixed: 'right',
    scopedSlots: { customRender: 'operation' },
  },
];

export const elementTemplateEditionRules = {
  title: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
  tempType: [{ required: true, message: '类型不能为空', trigger: 'change' }],
  cateId: [{ required: true, message: '分组不能为空', trigger: 'change' }],
  sortNum: [{ required: true, message: '排序不能为空', trigger: 'change' }],
};

export const elementMaterialEditionRules = {
  title: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
  url: [{ required: true, message: '文件不能为空', trigger: 'change' }],
  category: [{ required: true, message: '分组不能为空', trigger: 'change' }],
  sortNum: [{ required: true, message: '排序不能为空', trigger: 'blur' }],
};

export const elementImageEditionRules = {
  title: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
  url: [{ required: true, message: '文件不能为空', trigger: 'change' }],
  category: [{ required: true, message: '分组不能为空', trigger: 'change' }],
  sortNum: [{ required: true, message: '排序不能为空', trigger: 'blur' }],
};

export const elementFontEditionRules = {
  preview: [{ required: true, message: '封面不能为空', trigger: 'change' }],
  ttf: [{ required: true, message: '字体文件不能为空', trigger: 'change' }],
  value: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
  sortNum: [{ required: true, message: '排序不能为空', trigger: 'blur' }],
};

export const elementFilterEditionRules = {
  thumb: [{ required: true, message: '封面不能为空', trigger: 'change' }],
  fileUrl: [{ required: true, message: '滤镜文件不能为空', trigger: 'change' }],
  title: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
  sortNum: [{ required: true, message: '排序不能为空', trigger: 'blur' }],
};
