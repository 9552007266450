<template>
  <div>
    <a-modal
      :title="form.id ? '编辑贴纸' : '新增贴纸'"
      v-model="visible"
      width="450px"
      :confirmLoading="saveLoading"
      @ok="save"
      @cancel="onClose"
      :maskClosable="false"
    >
      <a-spin :spinning="loading">
        <a-form-model
          ref="form"
          :model="form"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 18 }"
          :rules="elementMaterialEditionRules"
        >
          <a-form-model-item label="名称" prop="title">
            <a-input v-model="form.title" placeholder="请输入名称"></a-input>
          </a-form-model-item>
          <a-form-model-item label="文件" prop="url">
            <base-upload
              style="height: 105px"
              :upload-text="'上传文件'"
              :upload-title="''"
              :accept-list="'.jpg,.jpeg,.png,.svg'"
              :has-file-list="uploadFileList"
              :limit-size="1"
              :params-data="uploadParamsData"
              :is-limit-image-size="false"
              :before-upload="beforeUpload"
              :customRequest="useCustomRequest ? customRequest : null"
              @changeFile="uploadFileList = []"
              @handleUrl="handleUrl"
              @handlePreview="(e) => openBigImg(e)"
            />
            <div>支持扩展名：.jpg .jpeg .png .svg</div>
          </a-form-model-item>
          <a-form-model-item label="分组" prop="category">
            <a-select v-model="form.category" placeholder="请选择分组" style="width: 100%" allowClear>
              <a-select-option v-for="item in categoryList" :key="item.id" :value="item.id">{{
                item.cateName
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="排序" prop="sortNum">
            <a-input-number
              v-model="form.sortNum"
              :precision="0"
              placeholder="请输入排序数字"
              style="width: 100%"
            ></a-input-number>
            <div class="tips">数字越大越靠前</div>
          </a-form-model-item>
        </a-form-model>
      </a-spin>
      <div slot="footer">
        <a-button type="primary" @click="save()" :loading="saveLoading">确认</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { templateTypeList } from '@/constant/xhs.js';
import {
  getAllCategoryListApi,
  addMaterialComponentApi,
  updateMaterialComponentApi,
  getMaterialDetailApi,
  uploadSvgMaterialApi,
} from '@/api/element.js';
import { elementMaterialEditionRules } from '../data';
import _ from 'lodash';
import BaseUpload from '@/components/BaseUpload/BaseUpload.vue';

export default {
  components: {
    BaseUpload,
  },
  data() {
    return {
      templateTypeList,
      elementMaterialEditionRules,
      visible: false,
      loading: false,
      saveLoading: false,
      form: {
        id: '',
        category: undefined,
        height: 0,
        model: '',
        original: '',
        sortNum: '',
        state: 1,
        thumb: '',
        title: '',
        type: '',
        url: '',
        width: 0,
      },
      categoryList: [],

      // 上传相关
      useCustomRequest: false,
      uploadFileList: [],
      uploadParamsData: {
        appId: 'bfz',
        filePath: '/bfz/material/',
      },
    };
  },
  methods: {
    openModal(id) {
      Object.assign(this.$data, this.$options.data());
      this.form.id = id;
      this.getAllCategoryList();
      if (id) this.getMaterialDetail(id);
      this.visible = true;
    },
    onClose() {
      this.$refs.form.clearValidate();
      this.visible = false;
    },
    openBigImg(url) {
      this.$emit('preview', url);
    },

    async getMaterialDetail(id) {
      try {
        this.loading = true;
        const { code, data, message } = await getMaterialDetailApi({ id });

        if (code === 200) {
          if (data.type === 'image') {
            this.uploadFileList = [
              {
                uid: -1,
                name: 'image/png',
                status: 'done',
                url: data.url,
              },
            ];
          } else {
            this.uploadFileList = [
              {
                uid: -1,
                name: 'image/png',
                status: 'done',
                url: data.thumb,
              },
            ];
          }
          this.form = { ...data, cateId: data.cateId || undefined, tempType: data.tempType || undefined };
        } else {
          this.$message.error(message);
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getAllCategoryList() {
      const { code, data, message } = await getAllCategoryListApi({
        type: 2,
      });
      if (code === 200) {
        this.categoryList = data;
      } else {
        this.$message.error(message);
      }
    },

    getImageSize(url) {
      return new Promise(function (resolve, reject) {
        let image = new Image();
        image.onload = function () {
          resolve({
            width: image.width,
            height: image.height,
          });
        };
        image.onerror = function () {
          reject(new Error('error'));
        };
        image.src = url;
      });
    },

    beforeUpload(file) {
      if (file.type === 'image/svg+xml') {
        this.useCustomRequest = true;
      } else {
        this.useCustomRequest = false;
      }
    },

    customRequest(option) {
      const { file, onSuccess } = option;
      const formData = new FormData();
      formData.append('file', file);
      return uploadSvgMaterialApi(formData)
        .then((res) => {
          if (res.code === 200) {
            onSuccess(res.data, res.data.thumb);
          } else {
            onSuccess();
            this.$nextTick(() => {
              this.uploadFileList = [];
            });
            this.$message.error(res.message);
          }
        })
        .catch(() => {
          onSuccess();
          this.$nextTick(() => {
            this.uploadFileList = [];
          });
        });
    },

    handleUrl(file) {
      if (!file.response) return;
      if (file.suffix === 'svg') {
        this.form.width = file.response.width;
        this.form.height = file.response.height;
        this.form.type = 'svg';
        this.form.model = file.response.model;
        this.form.url = file.response.url;
        this.form.thumb = file.response.thumb;
      } else {
        this.form.thumb = file.xhr;
        this.form.url = file.xhr;
        this.form.type = 'image';
        this.getImageSize(file.xhr).then((res) => {
          this.form.width = res.width;
          this.form.height = res.height;
        });
      }
    },

    async save() {
      this.saveLoading = true;
      try {
        const saveApi = this.form.id ? updateMaterialComponentApi : addMaterialComponentApi;
        const { code, message } = await saveApi(this.form);
        this.saveLoading = false;
        if (code === 200) {
          this.$message.success('保存成功');
          this.$emit('success');
          this.onClose();
        } else {
          this.$message.error(message);
        }
      } catch (error) {
        this.saveLoading = false;
      }
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
.tips {
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.35);
}
</style>
