<template>
  <div>
    <a-modal
      :title="form.id ? '编辑字体' : '新增字体'"
      v-model="visible"
      width="450px"
      :confirmLoading="saveLoading"
      @ok="save"
      @cancel="onClose"
      :maskClosable="false"
    >
      <a-spin :spinning="loading">
        <a-form-model
          ref="form"
          :model="form"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 18 }"
          :rules="elementFontEditionRules"
        >
          <a-form-model-item label="上传封面" prop="preview">
            <base-upload
              style="height: 105px"
              :upload-text="'上传封面'"
              :upload-title="''"
              :accept-list="'.jpg,.jpeg,.png'"
              :has-file-list="uploadCoverList"
              :limit-size="1"
              :params-data="uploadParamsData"
              :is-limit-image-size="false"
              @handleUrl="handleUrl"
              @handlePreview="(e) => openBigImg(e)"
            />
            <div>支持扩展名：.jpg .jpeg .png</div>
          </a-form-model-item>
          <a-form-model-item label="上传文件" prop="ttf">
            <base-upload
              style="height: 105px"
              :upload-text="'上传文件'"
              :upload-title="''"
              :accept-list="'.ttf'"
              :has-file-list="uploadFontList"
              :limit-size="10"
              :params-data="uploadParamsData"
              :is-limit-image-size="false"
              :is-show-preview-icon="false"
              @handleUrl="handleUrl"
            />
            <div>支持扩展名：.ttf</div>
          </a-form-model-item>
          <a-form-model-item label="字体名称" prop="value">
            <a-input v-model="form.value" placeholder="请输入名称"></a-input>
          </a-form-model-item>
          <a-form-model-item label="分组" prop="cateId">
            <a-select v-model="form.cateId" placeholder="请选择分组" style="width: 100%" allowClear>
              <a-select-option v-for="item in categoryList" :key="item.id" :value="item.id">{{
                item.cateName
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="排序" prop="sortNum">
            <a-input-number
              v-model="form.sortNum"
              :precision="0"
              placeholder="请输入排序数字"
              style="width: 100%"
            ></a-input-number>
            <div class="tips">数字越大越靠前</div>
          </a-form-model-item>
        </a-form-model>
      </a-spin>
      <div slot="footer">
        <a-button type="primary" @click="save()" :loading="saveLoading">确认</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { templateTypeList } from '@/constant/xhs.js';
import { getAllCategoryListApi, addFontApi, updateFontApi, getFontDetailApi } from '@/api/element.js';
import { elementFontEditionRules } from '../data';
import _ from 'lodash';
import BaseUpload from '@/components/BaseUpload/BaseUpload.vue';

export default {
  components: {
    BaseUpload,
  },
  data() {
    return {
      templateTypeList,
      elementFontEditionRules,
      visible: false,
      loading: false,
      saveLoading: false,
      form: {
        id: '',
        alias: '',
        cateId: undefined,
        fontFamily: '',
        lang: '',
        oid: '',
        preview: '',
        size: 0,
        sortNum: '',
        status: 1,
        ttf: '',
        value: '',
        version: ' ',
        woff: '',
        woffSize: 0,
      },
      categoryList: [],

      // 上传相关
      uploadCoverList: [],
      uploadFontList: [],
      uploadParamsData: {
        appId: 'bfz',
        filePath: '/bfz/font/',
      },
    };
  },
  methods: {
    openModal(id) {
      Object.assign(this.$data, this.$options.data());
      this.form.id = id;
      this.getAllCategoryList();
      if (id) this.getFontDetail(id);
      this.visible = true;
    },
    onClose() {
      this.$refs.form.clearValidate();
      this.visible = false;
    },
    openBigImg(url) {
      this.$emit('preview', url);
    },

    async getFontDetail(id) {
      try {
        this.loading = true;
        const { code, data, message } = await getFontDetailApi({ id });
        if (code === 200) {
          if (data.preview) {
            this.uploadCoverList = [
              {
                uid: -1,
                name: 'image/png',
                status: 'done',
                url: data.preview,
              },
            ];
          }
          if (data.ttf) {
            this.uploadFontList = [
              {
                uid: -1,
                name: 'ttf',
                status: 'done',
                url: data.ttf,
              },
            ];
          }
          data.cateId = data.cateId !== '0' ? data.cateId : undefined;
          this.form = data;
        } else {
          this.$message.error(message);
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getAllCategoryList() {
      const { code, data, message } = await getAllCategoryListApi({
        type: 5,
      });
      if (code === 200) {
        this.categoryList = data;
      } else {
        this.$message.error(message);
      }
    },

    getImageSize(url) {
      return new Promise(function (resolve, reject) {
        let image = new Image();
        image.onload = function () {
          resolve({
            width: image.width,
            height: image.height,
          });
        };
        image.onerror = function () {
          reject(new Error('error'));
        };
        image.src = url;
      });
    },

    handleUrl(file) {
      if (file.suffix === 'ttf') {
        this.form.ttf = file.xhr;
        this.form.size = file.size;
        this.form.alias = file.name.replace('.ttf', '');
        if (!this.form.value) {
          this.form.value = file.name.replace('.ttf', '');
          this.$refs.form.clearValidate('value');
        }
        this.$refs.form.clearValidate('ttf');
      } else {
        this.form.preview = file.xhr;
        this.$refs.form.clearValidate('preview');
      }
    },

    async save() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const saveApi = this.form.id ? updateFontApi : addFontApi;
          this.saveLoading = true;
          try {
            const { code, message } = await saveApi({ ...this.form, cateId: this.form.cateId || 0 });
            this.saveLoading = false;
            if (code === 200) {
              this.$message.success('保存成功');
              this.$emit('success');
              this.onClose();
            } else {
              this.$message.error(message);
            }
          } catch (error) {
            this.saveLoading = false;
          }
        }
      });
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
.tips {
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.35);
}
</style>
