<template>
  <a-tree-select
    v-model="inputValue"
    v-bind="$attrs"
    v-on="$listeners"
    :tree-data="tagList"
    :filterTreeNode="filterTreeNode"
  >
  </a-tree-select>
</template>

<script>
import api from '@/api/xhsAgencyApi';

export default {
  props: {
    value: { default: () => [] },
    isAll: { default: () => false },
    isAccept: { default: () => false },
    acceptList: { default: () => [] },
  },
  data() {
    return {
      inputValue: undefined,
      tagList: [],
      tagListFlat: [],
    };
  },
  watch: {
    inputValue(val) {
      let tagName = '';
      if (val) tagName = this.flattenTree(this.tagList)?.find((tag) => tag.value === val)?.name || undefined;
      this.$emit('input', val, tagName);
    },
    value(val) {
      if (val && val.includes('all')) {
        this.inputValue = this.tagListFlat.map((item) => {
          return item.value;
        });
      } else {
        this.inputValue = val;
      }
    },
  },
  methods: {
    // 选题降维
    flattenTree(tree) {
      const result = [];
      function traverse(node) {
        result.push(node);
        if (node.children) {
          for (const child of node.children) {
            traverse(child);
          }
        }
      }

      for (const item of tree) {
        traverse(item);
      }

      return result;
    },

    initTagValue(data) {
      if (data.children && data.children.length > 0) {
        data.children = data.children.map(this.initTagValue);
      }
      return {
        ...data,
        name: data.tagName,
        value: data.id,
        key: data.id,
        disabled: data.id === this.originId,
        // disabled: data.children.length > 0,
      };
    },
    async getTagList() {
      try {
        const { code, data, message } = await api.getTopicTagTree(0);
        if (code === 200) {
          this.tagList = data.map(this.initTagValue);
          if (this.isAll) {
            this.tagListFlat = this.flattenTree(this.tagList);
            this.tagList.unshift({
              name: '全选',
              value: 'all',
            });
          }
        } else {
          return this.$message.error(`${message}`);
        }
      } catch (error) {
        this.$message.error('操作失败');
      }
    },
    filterTreeNode(inputValue, treeNode) {
      return treeNode.data.props.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
    },
  },
  created() {
    this.inputValue = this.value;
    if (this.isAccept) {
      this.tagList = this.acceptList
    } else {
      this.getTagList();
    }
  },
};
</script>

<style scoped lang="scss"></style>
